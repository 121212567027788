import React from "react"
import { Link, graphql } from "gatsby"
import { RiArrowRightLine, RiArrowLeftLine } from "react-icons/ri"

import Layout from "../components/layout"
import SEO from '../components/seo';

const Pagination = (props) => (
  <div className="pagination -post">
    <ul>
        {(props.previous && props.previous.frontmatter.template === 'article-post') && (
          <li>
              <Link to={props.previous.frontmatter.slug} rel="prev">
                <p><span className="icon -left"><RiArrowLeftLine/></span> Previous</p>
                <span className="page-title">{props.previous.frontmatter.title}</span>
              </Link>
          </li>
        )}
        {(props.next && props.next.frontmatter.template === 'article-post') && (
          <li>
            <Link to={props.next.frontmatter.slug} rel="next">
              <p>Next <span className="icon -right"><RiArrowRightLine/></span></p>
              <span className="page-title">{props.next.frontmatter.title}</span>
            </Link>
          </li>
        )}
    </ul>
  </div>
)

const Post = ({ data, pageContext }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html,} = markdownRemark
  const { previous, next } = pageContext

  let props = {
    previous,
    next
  }

  return (
    <Layout className="page">
      <SEO
        title={frontmatter.title}
        article={true}
      />
      <article className="article-post">
        <header className="featured-banner">
          <section className="article-header">
			{frontmatter.categories ?
				frontmatter.categories.map((category, index) =>
				<span className="tag tag-primary">{category.category}</span>
			): ""}
            <h1>{frontmatter.title}</h1>
            <time>{frontmatter.date}</time>
            <div className="memo">{frontmatter.memo}</div>
          </section>
        </header>
        
        <div
          className="article-post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </article>
      {(previous || next) && (
        <Pagination {...props} />
      )}
    </Layout>
  )
}

export default Post

export const pageQuery = graphql`
  query ArticlePostQuery($id: String!) {
    markdownRemark( 
      id: { eq: $id }
    ) {
      id
      html
      frontmatter {
        date(formatString: "YYYY-MM-DD")
        slug
        title
		memo
		categories {
			category
		}
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1980, maxHeight: 768, quality: 80, srcSetBreakpoints: [350, 700, 1050, 1400]) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
            sizes {
              src
            }
          }
        }
      }
    }
  }
`
